var site = site || {};

(function($) {
  site.gdpr = {
    gdprRemInfoMessageBox: function() {
      $('.info-msg').remove();
    },

    gdprSetInfoBoxPosition: function(infoLink, infoMessageBox, infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt(infoMessageBox.css('max-width').replace('px', ''));

      if (isMobile) {
        infoMsgArrow.css({
          left: (infoLinkLeft - 10) + 'px'
        });
        infoLinkLeft = 10;
      } else if ((infoMessageBox.outerWidth(true) === infoMsgMaxWidth) && ((infoLinkLeft + (infoMsgMaxWidth + 25)) > screen.width)) {
        infoLinkLeft = infoLinkLeft - 480;
        infoMsgArrow.addClass('top-right-arrow');
      }
      infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function(context) {
      if (Drupal.settings.globals_variables.gdpr_compliant) {
        var isMobile = typeof Unison !== 'undefined' ? Unison.fetch.now().name === 'small' : $.cookie('client.isMobile');

        $('body', context).on('click', function (e) {
          if (e.target.className !== 'info-msg' && e.target.className !== 'info-msg__txt') {
            $('.info-msg').addClass('hidden');
          }
        });

        $(context).on('mousedown', '.info-msg__txt a', function (mouseEvent) {
          mouseEvent.preventDefault();
        });

        $(context).on('click', '.info-link', function (infoEvent) {
          infoEvent.preventDefault();
          var infoLink = $(infoEvent.currentTarget);

          site.gdpr.gdprRemInfoMessageBox();

          $("<div class='info-msg'><div class='info-msg__arrow'></div><div class='info-msg__txt'></div></div>").appendTo('body');
          var infoBoxEle = $('#' + infoLink.attr('message-in'));
          var infoMessageBox = $('.info-msg');
          var infoMsgArrow = $('.info-msg__arrow');

          $('.info-msg__txt').html("<div class='info-msg__close'>X</div>" + infoBoxEle.html());

          $(context).on('click', '#cboxClose, .info-msg__close', function (closeEvent) {
            closeEvent.stopPropagation();
            site.gdpr.gdprRemInfoMessageBox();
          });

          site.gdpr.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile);
          $(window).on('resize-debounced', site.gdpr.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile));
        });
      }
    },
    preselectCheckbox(context) {
      generic.jsonrpc.fetch({
        method: 'user.json',
        onSuccess: function (jsonRpcResponse) {
          var userData = jsonRpcResponse.getValue();

          if (userData.pc_email_optin && userData.signed_in) {
            $('input[name=PC_EMAIL_PROMOTIONS]', context).prop('checked', true);
          }
        }
      });
    }
  };

  Drupal.behaviors.gdpr = {
    attach: function(context) {
      site.gdpr.gdprToolTip(context);
      site.gdpr.preselectCheckbox(context);
    }
  };
})(jQuery);
